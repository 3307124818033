import apiCall from '../utils/api';

export const getConfig = async (locale) => {
  const url = `/cms/v1/configuration?locale=${locale}`;
  const apiArgs = {
    API_CALL: {
      method: 'GET'
    },
    url
  };
  const res = await apiCall(apiArgs);
  return res;
};

export default getConfig;
