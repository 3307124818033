import { atom } from 'recoil';

export const notifications = atom({
  key: 'notifications',
  default: {
    cartPopup: null,
    productsLoad: false,
    toast: false
  }
});

export default notifications;
