import { atom } from 'recoil';

export const config = atom({
  key: 'config',
  default: {
    banners: [],
    notifyMessage: '',
    loaded: false
  }
});

export default config;
