import { colorOrder } from '../links.config';
import apiCall from '../utils/api';

export const mapProducts = (products, isReviews = false) => {
  const mappedProducts = {};
  products.forEach((product) => {
    mappedProducts[`${product?.category}_${product?.subCategory}${isReviews ? '_SG' : ''}`] = {
      id: product?.id,
      name: product?.name,
      type: product?.type || null,
      country: product?.countryCode,
      category: product?.category,
      page: product?.page?.code,
      subCategory: product?.subCategory,
      currency: product?.currency,
      colorValues: getProductVariants(product?.variants)
    };
  });
  return mappedProducts;
};

const getProductVariants = (variants) => {
  variants.sort((a, b) => colorOrder.indexOf(a.color) - colorOrder.indexOf(b.color));
  const productVariants = {};
  variants.forEach((variant) => {
    productVariants[variant.color || 'DEFAULT'] = {
      ...variant.product,
      title: variant.color
    };
  });
  return productVariants;
};

export const fetchProducts = async () => {
  const url = `/cms/v1/product-catalogue`;
  const apiArgs = {
    API_CALL: {
      method: 'GET'
    },
    url
  };
  const res = await apiCall(apiArgs);
  return mapProducts(res || []);
};

export const fetchProductsSG = async () => {
  const url = `/cms/v1/product-catalogue?countrycode=SG`;
  const apiArgs = {
    API_CALL: {
      method: 'GET'
    },
    url,
    isCountryCodeRequired: false
  };
  const res = await apiCall(apiArgs);
  return mapProducts(res || [], true);
};

export const fetchProductLists = async (tags, locale) => {
  // const url = tags.length ? `/cms/v1/catalogue-products?tags[]=${tags.join('&tags[]=')}`
  //   : `/cms/v1/catalogue-products`;
  let url = '';
  if (tags.length) {
    url = locale !== undefined ? `/cms/v1/catalogue-products?tags[]=${tags.join('&tags[]=')}&locale=${locale}`
      : `/cms/v1/catalogue-products?tags[]=${tags.join('&tags[]=')}`;
  } else {
    url = locale !== undefined ? `/cms/v1/catalogue-products?locale=${locale}` : `/cms/v1/catalogue-products`;
  }

  const apiArgs = {
    API_CALL: {
      method: 'GET'
    },
    url
  };
  const res = await apiCall(apiArgs);
  return res;
};

export default fetchProducts;
