import PropTypes from 'prop-types';

import useI18n from '../../hooks/use-i18n';

const NavbarProduct = (props) => {
  const { id,
    dataKey,
    shopifyHref,
    onClickFunction,
    content,
    isDesktopLink,
    isCommonLink,
    imgSrc,
    isMobileLink } = props;

  const i18n = useI18n();
  const navbar = i18n.t('navbar');

  if (isMobileLink) {
    return (
      <a
        data-lokalise=""
        data-key={dataKey}
        referrerPolicy="no-referrer"
        rel="noreferrer"
        href={shopifyHref}
        onClick={onClickFunction}
        id={id}
        target="_blank"
      >
        <p>
          {navbar[content]}
        </p>
      </a>);
  }

  if (isDesktopLink || isCommonLink) {
    return (
      <a
        data-lokalise=""
        data-key={dataKey}
        onClick={onClickFunction}
        id={id}
        referrerPolicy="no-referrer"
        rel="noreferrer"
        href={shopifyHref}
        className="shopify-links"
        target="_blank"
      >
        <img src={imgSrc} data-gumlet="false" alt="shopify" />
        <p>
          {navbar[content]}
        </p>
      </a>);
  }
  return <></>;
};

NavbarProduct.propTypes = {
  content: PropTypes.string,
  dataKey: PropTypes.string,
  id: PropTypes.string,
  imgSrc: PropTypes.string,
  isCommonLink: PropTypes.bool,
  isDesktopLink: PropTypes.bool,
  isMobileLink: PropTypes.bool,
  onClickFunction: PropTypes.func,
  shopifyHref: PropTypes.string
};

NavbarProduct.defaultProps = {
  content: '',
  dataKey: '',
  id: '',
  imgSrc: '',
  isCommonLink: false,
  isDesktopLink: false,
  isMobileLink: false,
  onClickFunction: () => {},
  shopifyHref: ''
};

export default NavbarProduct;
