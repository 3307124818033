/* eslint-disable react/no-danger */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-script-url */
import { useState, useEffect, useRef } from 'react';

import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Marquee from 'react-double-marquee';
import { useRecoilValue, useRecoilState } from 'recoil';

import { buttonClickEvent, hamburgerMenuClicked, headerCategoryClicked, headerCategoryDropdownClicked, headerCategoryDropdownDiscoverClicked,
  shopAllClicked } from '../../helpers/segment';
import useI18n from '../../hooks/use-i18n';
import useUtmParam from '../../hooks/useUtmParams';
import { countryMap } from '../../language.config';
import { freshMarkets, externalLinks, sonicMarkets, sonicBrushHeadMarkets, sonicGoBrushHeadMarkets,
  waterflosserMarkets, faqDisabledMarkets, cartDisabledMarkets, sonicGoMarkets, whiteningMarkets,
  waterflosserTipsMarkets, sonicStoryMarkets, flossMarkets,
  breathshieldMarkets, sonicGentleBrushHeadMarkets, freshBundleMarkets, toothpasteMarkets, shopifyEnabledMarkets, pokemonCollectionEnabledMarkets, sonicGoSetEnabledMarkets,
  obt3EnabledMarkets } from '../../links.config';
import { accountMenu } from '../../recoil/atoms/accountMenu';
import { config } from '../../recoil/atoms/config';
import END_POINTS, { CATEGORY_URL, PAGES_URL, PRODUCT_URL } from '../../utils/shopify-endpoints';
import { SHOPIFY_URLS, checkRender, useUtmParam as useUtmData } from '../../utils/utils';
import HomepageSubbar from '../includes/homepage-subbar';
import ToastItem from '../includes/toast-item';
import NavbarDiscover from '../listing/navbar-discover';
import NavbarProduct from '../listing/navbar-products';

import '../../styles/revamp/header.scss';

const AlignerSubbar = dynamic(() => import('../includes/aligner-subbar'), { ssr: false });
export default function Header(props) {
  const { page, segName, headerCategory } = props;
  const [isMenuOpen, toggleIsMenuOpen] = useState(false);
  const [isSticky, toggleIsSticky] = useState(false);
  const [menuState, toggleMenuState] = useRecoilState(accountMenu);
  const [activeMenu, setActiveMenu] = useState(0);
  const [isAccountMenuOpen, toggleIsAccountMenuOpen] = useState(false);
  const [isProductPage, toggleIsProductPage] = useState(false);
  const [isNotification, toggleIsNotification] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const [isProductDetailPage, toggleIsProductDetailPage] = useState(false);
  const [utmParams, setUtmParams] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const dropdown = useRef();
  const accountdropdown = useRef();
  const router = useRouter();
  const query = router && router.query;
  const configData = useRecoilValue(config);

  const scrollEventListner = () => {
    toggleIsSticky(window.pageYOffset > 80);
  };

  useEffect(() => {
    const { body } = document;
    const scrollUp = 'scroll-up';
    const scrollDown = 'scroll-down';
    let lastScroll = 0;

    window.addEventListener('scroll', () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll <= 0) {
        body.classList.remove(scrollUp);
        return;
      }
      if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
        body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
      } else if (
        currentScroll < lastScroll
        && body.classList.contains(scrollDown)
      ) {
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
      }
      lastScroll = currentScroll;
    });
  });

  useEffect(() => {
    toggleIsProductPage(document.querySelector('.zenyum-product-page'));
    toggleIsProductDetailPage(document.querySelector('.sonic-head-wrapper'));
    window.addEventListener('scroll', scrollEventListner);
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('mousedown', AccounthandleClick);
    return () => {
      window.removeEventListener('scroll', scrollEventListner);
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('mousedown', AccounthandleClick);
    };
  }, []);

  useEffect(() => {
    toggleIsNotification(document.querySelector('.product-notify-bar'));
  }, [configData]);

  useEffect(() => {
    if (menuState) {
      toggleIsMenuOpen(true);
      toggleIsAccountMenuOpen(true);
      toggleMenuState(false);
    }
  }, [menuState]);

  useEffect(() => {
    setIsOverflow(document.querySelector('.product-notify-bar p')?.offsetWidth > window.innerWidth);
  }, [configData]);

  useEffect(() => {
    setTimeout(() => {
      setUtmParams(useUtmData('Header'));
    }, 50);
  }, [query]);

  useEffect(() => {
    setIsMobile(window.innerWidth < 1056);
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < 1056);
    });
    return () => {
      window.removeEventListener('resize');
    };
  }, []);

  const handleClick = (e) => {
    if (dropdown.current && dropdown.current.contains(e.target)) {
      return false;
    }
  };

  const AccounthandleClick = (e) => {
    if (accountdropdown.current && accountdropdown.current.contains(e.target)) {
      return;
    }
    toggleIsAccountMenuOpen(false);
  };

  const buttonClick = (btnId, btnName) => {
    buttonClickEvent(
      btnId,
      btnName,
      'Header',
      segName,
      btnName,
      countryMap[country]
    );
  };

  const i18n = useI18n();
  const codes = i18n.activeLocale.split('-');
  const country = codes.length === 2 ? codes[0] : 'sg';
  const lang = codes[1] || 'en';
  const COUNTRY_LANGUAGE = i18n.activeLocale;
  const productLinks = externalLinks[country] || externalLinks.sg;
  const navbar = i18n.t('navbar');
  const orientationMsg = i18n.t('orientation');
  const headerClass = [isSticky && 'sticky', isMenuOpen && 'menu-open', !isNotification && 'disable-notify-bar', isProductPage
    && 'product-page-header', isProductDetailPage && 'detail-page-header'].filter((e) => !!e).join(' ');

  const isShopifyEnabledMarket = shopifyEnabledMarkets.includes(country);

  // cartDisabledMarkets.includes(country) && 'non-cp-header' class nor non cp old design

  return (
    <header className={headerClass}>
      {configData.notifyMessage && (
        <div className={`top-notify-bar product-notify-bar ${isOverflow ? '' : 'no-overflow'}`}>
          <div className="container">
            <Marquee direction="left" speed={0.03}>
              <p dangerouslySetInnerHTML={{ __html: configData.notifyMessage }} />
            </Marquee>
          </div>
        </div>
      )}
      <div className={`main-bar ${isMenuOpen ? 'active' : ''}`}>
        <div className="logo-bar">
          <div className="container">
            <div className="logo-bar-wrapper">
              {!isAccountMenuOpen && (
                <div
                  className="ham-menu"
                  onClick={() => { toggleIsMenuOpen(!isMenuOpen); buttonClick('ham-menu', 'Menu'); hamburgerMenuClicked(countryMap[country]); }}
                  role="presentation"
                >
                  <span className={`menu-button ${isMenuOpen ? 'active' : ''}`}>
                    <span className="line" />
                    <span className="line" />
                    <span className="line" />
                  </span>
                </div>
              )}
              <div className="logo-wrapper">
                <a
                  className="nav-logo"
                  href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}`}
                  onClick={() => { buttonClick('nav-logo', 'Logo'); }}
                >
                  {' '}
                  {country === 'tw' ? (
                    <img src="/img/new-logo-tw.svg" data-gumlet="false" alt="Zenyum Logo" />
                  ) : (
                    <img src="/img/logo-new.svg" data-gumlet="false" alt="Zenyum Logo" />
                  )}
                </a>
              </div>
              <div className={`side-wrapper ${cartDisabledMarkets.includes(country) ? 'non-cp-wrap' : ''}`} ref={accountdropdown}>
                <a className="sidebar-dropdown">
                  <p>
                    <a
                      data-lokalise=""
                      data-key="navbar::about"
                      href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about`}
                      className={headerCategory === 'about' ? 'selected-cat' : ''}
                    >
                      {navbar.about}
                    </a>
                  </p>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        data-lokalise=""
                        href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-whatwedo`}
                        data-key="navbar::about_revamp_link_1"
                      >
                        {navbar.about_revamp_link_1}
                      </a>
                    </li>
                    <li>
                      <a
                        data-lokalise=""
                        href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-story`}
                        data-key="navbar::about_revamp_link_2"
                      >
                        {navbar.about_revamp_link_2}
                      </a>
                    </li>
                    <li>
                      <a
                        data-lokalise=""
                        href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-journey`}
                        data-key="navbar::about_revamp_link_3"
                      >
                        {navbar.about_revamp_link_3}
                      </a>
                    </li>
                    <li>
                      <a
                        data-lokalise=""
                        data-key="navbar::about_revamp_link_4"
                        href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-partner`}
                      >
                        {navbar.about_revamp_link_4}
                      </a>
                    </li>
                    <li>
                      <a
                        data-lokalise=""
                        data-key="navbar::about_revamp_link_5"
                        href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-highlight`}
                      >
                        {navbar.about_revamp_link_5}
                      </a>
                    </li>
                    {country !== 'th' && (
                    <li>
                      <a
                        data-lokalise=""
                        data-key="navbar::about_revamp_link_6"
                        href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-community`}
                      >
                        {navbar.about_revamp_link_6}
                      </a>
                    </li>
                    )}
                    {!cartDisabledMarkets.includes(country) && (
                    <li>
                      <a
                        data-lokalise=""
                        data-key="navbar::about_revamp_link_7"
                        href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-join`}
                      >
                        {navbar.about_revamp_link_7}
                      </a>
                    </li>
                    )}
                  </ul>
                </a>
                <a className="sidebar-dropdown">
                  {navbar.discover}
                  <ul className="dropdown-menu">
                    {!faqDisabledMarkets.includes(country) && (
                    <li>
                      <a
                        data-lokalise=""
                        data-key="navbar::faqs"
                        href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/faqs`}
                      >
                        {navbar.faqs}
                      </a>
                    </li>
                    )}
                    <li>
                      <a
                        data-lokalise=""
                        data-key="navbar::discover_link_1"
                        href={`${country !== 'hk' ? externalLinks[country].blog : externalLinks[country][lang].blog}${useUtmParam(query)}`}
                        id="navbar-blog"
                      >
                        {navbar.blog}
                      </a>
                    </li>
                    <li>
                      <a
                        data-lokalise=""
                        data-key="navbar::for_dentists"
                        href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/dentist`}
                        id="navbar-for_dentists"
                      >
                        {navbar.for_dentists}
                      </a>
                    </li>
                    <li>
                      <a
                        data-lokalise=""
                        data-key="navbar::referral_rewards"
                        href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/referrals-mechanism`}
                        id="navbar-referral_rewards"
                      >
                        {navbar.referral_rewards}
                      </a>
                    </li>
                  </ul>
                </a>
              </div>

            </div>
          </div>
        </div>
        {/* <nav className={`${isMenuOpen ? 'active' : ''} ${cartDisabledMarkets.includes(country) ? 'non-cp-nav' : ''}`}> */}
        <nav className={isMenuOpen ? 'active' : ''}>
          <div className="top-fixed-section">
            &nbsp;
          </div>
          <div className="scrollabe-mobile">
            <ul className="main-nav">
              <li className="nav-blue mob-link">
                <a
                  data-lokalise=""
                  data-key="navbar::mob_link_1"
                  onClick={() => { buttonClick('navbar.mob_link_1', navbar.mob_link_1); }}
                  id="header-assessment-link-2"
                  href={`${obt3EnabledMarkets.includes(country) ? process.env.NEXT_PUBLIC_OBT3_LINK
                    : process.env.NEXT_PUBLIC_OBT_LINK}${country}-${lang}/intro/${utmParams}`}
                >
                  <span>{navbar.mob_link_1}</span>
                </a>
              </li>
              <li>
                <a
                  data-lokalise=""
                  data-key="navbar::menu_item_1"
                  href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/invisible-braces`}
                  onClick={() => { headerCategoryClicked(countryMap[country], 'Invisible braces'); }}
                  id="header-invisiblebraces-link"
                  className={headerCategory === 'Invisible braces' ? 'selected-cat' : ''}
                >
                  <span>{navbar.menu_item_1}</span>
                </a>
              </li>
              {pokemonCollectionEnabledMarkets.includes(country) && (
              <li>
                <a
                  className={headerCategory === 'Pokemon Collections' ? 'selected-cat' : ''}
                  target="_blank"
                  data-key="navbar::menu_item_8"
                  data-lokalise=""
                  id="header-pokemon-collection-link"
                  onClick={() => { headerCategoryClicked(countryMap[country], 'Pokemon Collections'); }}
                  referrerPolicy="no-referrer"
                  rel="noreferrer"
                  href={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PAGES_URL}${END_POINTS.ZENYUMSONICGO_POKEMON_COLLECTION}`}
                >
                  <span>{navbar.menu_item_8}</span>
                       &nbsp;
                  <sup>{navbar.new}</sup>
                </a>
              </li>
              )}
              {whiteningMarkets.includes(country) && (
                <li>
                  <a
                    className={headerCategory === 'Whitening' ? 'selected-cat' : ''}
                    target="_blank"
                    data-key="navbar::menu_item_7"
                    data-lokalise=""
                    id="header-whitening-link"
                    onClick={() => { headerCategoryClicked(countryMap[country], 'Whitening'); }}
                    referrerPolicy="no-referrer"
                    rel="noreferrer"
                    href={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${CATEGORY_URL}${END_POINTS.ZENYUMBRIGHT_TEETH_WHITENING_STRIPS}`}
                  >
                    <span>{navbar.menu_item_7}</span>
                  </a>
                </li>
              )}
              {!cartDisabledMarkets.includes(country) && (
              <>
                <li>
                  {(sonicMarkets.includes(country) || sonicGoMarkets.includes(country)) ? (
                    <a
                      data-lokalise=""
                      data-key="navbar::menu_item_2"
                      onClick={() => { headerCategoryClicked(countryMap[country], 'Electric toothbrushes'); setActiveMenu(activeMenu === 1 ? 0 : 1); return false; }}
                      id="header-electric-toothbrushes-link"
                      className={`${activeMenu === 1 ? 'active-cat' : ''} ${headerCategory === 'Electric toothbrush' ? 'selected-cat' : ''}`}
                      role="presentation"
                    >
                      {
                        checkRender(isMobile,
                          (<span role="presentation">{navbar.menu_item_2}</span>),
                          (
                            <a
                              referrerPolicy="no-referrer"
                              rel="noreferrer"
                              id="header-electric-toothbrushes-category-link"
                              target="_blank"
                              href={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${CATEGORY_URL}${END_POINTS.ZENYUMSONIC_ELECTRIC_TOOTHBRUSHES}`}
                              className="shopify-links"
                            >
                              <span role="presentation">{navbar.menu_item_2}</span>
                            </a>
                          ))
                      }
                      <span className="plus" />
                      <div className={`sub-nav ${activeMenu === 1 ? 'active-menu' : ''}`} style={{ display: 'none' }}>
                        <h4>{navbar.featured}</h4>
                        <ul>
                          {/* Desktop Link starts here */}
                          {sonicMarkets.includes(country) && (
                            <li className="desktop-link">
                              <NavbarProduct
                                dataKey="navbar::menu_brush_1"
                                onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Electric toothbrushes', 'Sonic toothbrush + Brushhead'); }}
                                id="header-menu_brush_1-link"
                                imgSrc="/img/updated/navbar/menu/brush_1.png"
                                shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUMSONIC_TOOTHBRUSH_AND_BRUSH_HEAD_THREE_PACK}`}
                                content="menu_brush_1"
                                isDesktopLink={true}
                              />
                            </li>
                          )}
                          {sonicGoSetEnabledMarkets.includes(country) && (
                            <li className="desktop-link">
                              <NavbarProduct
                                dataKey="navbar::menu_brush_2"
                                shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUMSONIC_GO_TRAVEL_SET}`}
                                onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Electric toothbrushes', 'SonicGo Travelset'); }}
                                id="header-menu_brush_2-link"
                                imgSrc="/img/updated/navbar/menu/brush_2.png"
                                isDesktopLink={true}
                                content="menu_brush_2"
                              />
                            </li>
                          )}
                          {sonicMarkets.includes(country) && (
                            <li className="desktop-link">
                              <NavbarProduct
                                dataKey="navbar::menu_brush_3"
                                onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Electric toothbrushes', 'Sonic toothbrush'); }}
                                id="header-menu_brush_3-link"
                                imgSrc="/img/updated/navbar/menu/brush_3.png"
                                content="menu_brush_3"
                                isDesktopLink={true}
                                shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUMSONIC_TOOTHBRUSH}`}
                              />
                            </li>
                          )}
                          {sonicBrushHeadMarkets.includes(country) && (
                          <li className="desktop-link">
                            <NavbarProduct
                              dataKey="navbar::menu_brush_4"
                              shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUMSONIC_BRUSH_HEAD_THREE_PACK}`}
                              onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Electric toothbrushes', 'Sonic Brushhead'); }}
                              id="header-menu_brush_4-link"
                              content="menu_brush_4"
                              imgSrc="/img/updated/navbar/menu/brush_4.png"
                              isDesktopLink={true}
                            />
                          </li>
                          )}
                          {sonicGoBrushHeadMarkets.includes(country) && (
                          <li className="desktop-link">
                            <NavbarProduct
                              dataKey="navbar::menu_brush_5"
                              shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUMSONIC_GO_BRUSH_HEAD_THREE_PACK}`}
                              onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Electric toothbrushes', 'SonicGo Brushhead'); }}
                              id="header-menu_brush_5-link"
                              imgSrc="/img/updated/navbar/menu/brush_5.png"
                              content="menu_brush_5"
                              isDesktopLink={true}
                            />
                          </li>
                          )}
                          {/* Desktop Link Ends here */}
                          {/* Mobile Link starts here */}
                          {sonicMarkets.includes(country) && (
                            <li className="mobile-link">
                              <NavbarProduct
                                dataKey="navbar::menu_brush_mobile_1"
                                onClick={() => { headerCategoryDropdownClicked(countryMap[country], 'Electric toothbrushes', 'Sonic Toothbrush'); }}
                                id="header-menu_brush_mobile_1-link"
                                shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUMSONIC_TOOTHBRUSH}`}
                                content="menu_brush_mobile_1"
                                isMobileLink={true}
                                imgSrc=""
                              />
                            </li>
                          )}
                          {sonicGoMarkets.includes(country) && (
                          <li className="mobile-link">
                            <NavbarProduct
                              dataKey="navbar::menu_brush_mobile_2"
                              onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Electric toothbrushes', 'SonicGo Toothbrush'); }}
                              id="header-menu_brush_mobile_2-link"
                              content="menu_brush_mobile_2"
                              shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUMSONIC_GO_TOOTHBRUSH}`}
                              isMobileLink={true}
                            />
                          </li>
                          )}
                          {sonicBrushHeadMarkets.includes(country) && (
                          <li className="mobile-link">
                            <NavbarProduct
                              dataKey="navbar::menu_brush_mobile_3"
                              shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUMSONIC_BRUSH_HEAD_THREE_PACK}`}
                              onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Electric toothbrushes', 'Sonic Brushhead'); }}
                              id="header-menu_brush_mobile_3-link"
                              content="menu_brush_mobile_3"
                              isMobileLink={true}
                            />

                          </li>
                          )}
                          {sonicGoBrushHeadMarkets.includes(country) && (
                          <li className="mobile-link">
                            <NavbarProduct
                              dataKey="navbar::menu_brush_mobile_4"
                              isMobileLink={true}
                              onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Electric toothbrushes', 'SonicGo Brushhead'); }}
                              id="header-menu_brush_mobile_4-link"
                              content="menu_brush_mobile_4"
                              shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUMSONIC_GO_BRUSH_HEAD_THREE_PACK}`}
                            />
                          </li>
                          )}
                          {/* Mobile Link ends here */}
                        </ul>
                        <NavbarDiscover
                          shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${CATEGORY_URL}${sonicStoryMarkets.includes(country)
                            ? END_POINTS.ZENYUMSONIC_STORY : END_POINTS.ZENYUMSONIC_ELECTRIC_TOOTHBRUSHES}`}
                          dataKey="navbar::discover_all"
                          onClickFunction={() => {
                            buttonClick('navbar.discover_all', navbar.discover_all);
                            headerCategoryDropdownDiscoverClicked(countryMap[country], 'Electric toothbrush');
                          }}
                          id="header-zenyumsonic-link"
                        />
                      </div>
                    </a>
                  ) : (<a href={productLinks.sonic} id="header-zenyumsonic-link">{navbar.menu_item_2}</a>)}
                </li>
                {freshMarkets.includes(country) && (
                <li>
                  <a
                    data-lokalise=""
                    data-key="navbar::menu_item_3"
                    onClick={() => { headerCategoryClicked(countryMap[country], 'Toothpastes & mouthwash'); setActiveMenu(activeMenu === 2 ? 0 : 2); return false; }}
                    id="header-toothpaste-mouthwash-link"
                    className={`${activeMenu === 2 ? 'active-cat' : ''} ${headerCategory === 'Toothpaste and Mouthwash' ? 'selected-cat' : ''}`}
                    role="presentation"
                  >
                    {checkRender(
                      isMobile,
                      (
                        <span role="presentation">{navbar.menu_item_3}</span>
                      ),
                      checkRender(isShopifyEnabledMarket, (
                        <a
                          referrerPolicy="no-referrer"
                          rel="noreferrer"
                          target="_blank"
                          id="header-toothpaste-mouthwash-category-link"
                          href={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${CATEGORY_URL}${END_POINTS.TOOTHPASTE_AND_MOUTHWASH}`}
                          className="shopify-links"
                        >
                          <span role="presentation">{navbar.menu_item_3}</span>
                        </a>)
                      , (
                        <Link href="/[lng]/toothpaste-and-mouthwash" as={`/${i18n.activeLocale}/toothpaste-and-mouthwash`} prefetch={false}>
                          <span role="presentation">{navbar.menu_item_3}</span>
                        </Link>
                      ))
                    )}
                    <span className="plus" />
                    <div className={`sub-nav ${activeMenu === 2 ? 'active-menu' : ''}`} style={{ display: 'none' }}>
                      <h4>{navbar.featured}</h4>
                      <ul>
                        {/* Desktop Link Ends here */}
                        {freshBundleMarkets.includes(country) && (
                          <li className="desktop-link">
                            <NavbarProduct
                              dataKey="navbar::menu_t_m_1"
                              onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Toothpastes & mouthwash', 'Fresh Bundle'); }}
                              id="header-menu_t_m_1-link"
                              imgSrc="/img/updated/navbar/menu/t_m_1.png"
                              content="menu_t_m_1"
                              isDesktopLink={true}
                              shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUMFRESH_BUNDLE}`}
                            />

                          </li>
                        )}
                        {toothpasteMarkets.includes(country) && (
                          <>
                            {country === 'vn' && (
                            <li className="desktop-link">
                              <NavbarProduct
                                dataKey="navbar::menu_t_m_5"
                                onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Toothpastes & mouthwash', 'Fresh Set'); }}
                                id="header-menu_t_m_5-link"
                                imgSrc="/img/updated/navbar/menu/t_m_5.png"
                                content="menu_t_m_5"
                                isMobile={true}
                                shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUMFRESH_TOOTHPASTE}`}
                              />
                            </li>
                            )}

                            <li className="desktop-link">
                              <NavbarProduct
                                dataKey="navbar::menu_t_m_2"
                                onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Toothpastes & mouthwash', 'Toothpaste Day'); }}
                                id="header-menu_t_m_2-link"
                                imgSrc="/img/updated/navbar/menu/t_m_2.png"
                                content="menu_t_m_2"
                                isDesktopLink={true}
                                shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUMFRESH_TOOTHPASTE}`}
                              />

                            </li>
                            <li className="desktop-link">
                              <NavbarProduct
                                data-key="navbar::menu_t_m_3"
                                onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Toothpastes & mouthwash', 'Toothpaste Night'); }}
                                id="header-menu_t_m_3-link"
                                imgSrc="/img/updated/navbar/menu/t_m_3.png"
                                content="menu_t_m_3"
                                shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUMFRESH_TOOTHPASTE}`}
                                isDesktopLink={true}
                              />
                            </li>
                          </>
                        )}
                        {breathshieldMarkets.includes(country) && (
                        <li className="desktop-link">
                          <NavbarProduct
                            dataKey="navbar::menu_t_m_4"
                            onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Toothpastes & mouthwash', 'Breathshield'); }}
                            id="header-menu_t_m_4-link"
                            imgSrc="/img/updated/navbar/menu/t_m_4.png"
                            content="menu_t_m_4"
                            isDesktopLink={true}
                            shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUMFRESH_BREATH_SHIELD}`}
                          />
                        </li>
                        )}

                        {/* Desktop Link Ends here */}
                        {/* Mobile Link starts here */}
                        {toothpasteMarkets.includes(country) && (
                          <>
                            <li className="mobile-link">
                              <NavbarProduct
                                dataKey="navbar::menu_t_m_2"
                                content="menu_t_m_2"
                                onClick={() => { headerCategoryDropdownClicked(countryMap[country], 'Toothpastes & mouthwash', 'Toothpaste Day'); }}
                                id="header-menu_t_mobile_1.1-link"
                                isMobileLink={true}
                                shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUMFRESH_TOOTHPASTE}`}
                              />
                            </li>
                            <li className="mobile-link">
                              <NavbarProduct
                                dataKey="navbar::menu_t_m_3"
                                onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Toothpastes & mouthwash', 'Toothpaste Night'); }}
                                id="header-menu_t_mobile_1.2-link"
                                isMobileLink={true}
                                content="menu_t_m_3"
                                shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUMFRESH_TOOTHPASTE}`}
                              />

                            </li>
                            {country === 'vn' && (
                            <li className="mobile-link">
                              <NavbarProduct
                                dataKey="navbar::menu_t_mobile_4"
                                onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Toothpastes & mouthwash', 'Fresh Set'); }}
                                id="header-menu_t_mobile_4-link"
                                content="menu_t_mobile_4"
                                isMobileLink={true}
                                shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${END_POINTS.ZENYUMFRESH_TOOTHPASTE}`}
                              />
                            </li>
                            )}
                          </>
                        )}
                        {freshBundleMarkets.includes(country) && (
                        <li className="mobile-link">
                          <NavbarProduct
                            isMobileLink={true}
                            shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUMFRESH_BUNDLE}`}
                            dataKey="navbar::menu_t_mobile_3"
                            onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Toothpastes & mouthwash', 'Fresh Bundles'); }}
                            id="header-menu_t_mobile_3-link"
                            content="menu_t_mobile_3"
                          />
                        </li>
                        )}
                        {breathshieldMarkets.includes(country) && (
                        <li className="mobile-link">
                          <NavbarProduct
                            shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUMFRESH_BREATH_SHIELD}`}
                            isMobileLink={true}
                            content="menu_t_mobile_2"
                            dataKey="navbar::menu_t_mobile_2"
                            onClick={() => { headerCategoryDropdownClicked(countryMap[country], 'Toothpastes & mouthwash', 'Breathshield'); }}
                            id="header-menu_t_mobile_2-link"
                          />
                        </li>
                        )}

                        {/* Mobile Link Ends here */}
                      </ul>
                      <NavbarDiscover
                        shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${CATEGORY_URL}${END_POINTS.TOOTHPASTE_AND_MOUTHWASH}`}
                        dataKey="navbar::discover_all"
                        onClickFunction={() => {
                          buttonClick('navbar.discover_all', navbar.discover_all);
                          headerCategoryDropdownDiscoverClicked(countryMap[country], 'Toothpastes & mouthwash');
                        }}
                        id="header-zenyumfresh-link"
                      />
                    </div>
                  </a>
                </li>
                )}
                {(flossMarkets.includes(country) || waterflosserMarkets.includes(country)) && (
                <li>
                  <a
                    data-lokalise=""
                    data-key="navbar::menu_item_4"
                    onClick={() => { headerCategoryClicked(countryMap[country], 'Floss'); setActiveMenu(activeMenu === 3 ? 0 : 3); return false; }}
                    id="header-floss-link"
                    className={`${activeMenu === 3 ? 'active-cat' : ''} ${headerCategory === 'Zenyum floss' ? 'selected-cat' : ''}`}
                    role="presentation"
                  >
                    {checkRender(
                      isMobile, (
                        <span role="presentation">{navbar.menu_item_4}</span>
                      ),
                      checkRender(isShopifyEnabledMarket, (
                        <a
                          referrerPolicy="no-referrer"
                          rel="noreferrer"
                          id="header-floss-category-link"
                          href={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${CATEGORY_URL}${END_POINTS.ZENYUMFLOSS}`}
                          className="shopify-links"
                          target="_blank"
                        >
                          <span role="presentation">{navbar.menu_item_4}</span>
                        </a>)
                      , (
                        <Link href="/[lng]/zenyumfloss" as={`/${i18n.activeLocale}/zenyumfloss`} prefetch={false}>
                          <span role="presentation">{navbar.menu_item_4}</span>
                        </Link>
                      ))
                    )}
                    <span className="plus" />
                    <div className={`sub-nav ${activeMenu === 3 ? 'active-menu' : ''}`} style={{ display: 'none' }}>
                      <h4>{navbar.featured}</h4>
                      <ul>
                        {/* Desktop Link starts here */}
                        {waterflosserMarkets.includes(country) && (
                        <>
                          <li className="desktop-link">
                            <NavbarProduct
                              shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUM_POWER_CLEAN_BUNDLE}`}
                              dataKey="navbar::menu_floss_1"
                              onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Floss', 'Power Clean Bundle'); }}
                              id="header-menu_floss_1-link"
                              isDesktopLink={true}
                              imgSrc="/img/updated/navbar/menu/floss_1.png"
                              content="menu_floss_1"
                            />
                          </li>
                          {/* Desktop Link Ends here */}
                          {/* Common Link starts here */}
                          <li className="common-link">
                            <NavbarProduct
                              shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUM_WATERFLOSSER_PRO}`}
                              dataKey="navbar::menu_floss_2"
                              onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Floss', 'Waterflosser Pro'); }}
                              id="header-menu_floss_2-link"
                              imgSrc="/img/updated/navbar/menu/floss_2.png"
                              content="menu_floss_2"
                              isCommonLink={true}
                            />
                          </li>
                        </>
                        )}
                        {flossMarkets.includes(country) && (
                        <li className="common-link">
                          <NavbarProduct
                            shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUM_FRESH_FLOSS}`}
                            isCommonLink={true}
                            dataKey="navbar::menu_floss_3"
                            onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Floss', 'Floss picks'); }}
                            id="header-menu_floss_3-link"
                            imgSrc="/img/updated/navbar/menu/floss_3.png"
                            content="menu_floss_3"
                          />
                        </li>
                        )}
                        {waterflosserTipsMarkets.includes(country) && (
                        <li className="common-link">
                          <NavbarProduct
                            shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUM_WATERFLOSSER_PRO_STANDARD_JET_TIPS_REFILL_PACK}`}
                            isCommonLink={true}
                            dataKey="navbar::menu_floss_4"
                            onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Floss', 'Waterflosser Tips'); }}
                            id="header-menu_floss_4-link"
                            imgSrc="/img/updated/navbar/menu/floss_4.png"
                            content="menu_floss_4"
                          />
                        </li>
                        )}
                        {/* Common Link Ends here */}
                        {/* Mobile Link starts here */}
                        {/* Mobile Link Ends here */}
                      </ul>
                      <NavbarDiscover
                        shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${CATEGORY_URL}${END_POINTS.ZENYUMFLOSS}`}
                        dataKey="navbar::discover_all"
                        onClickFunction={() => {
                          buttonClick('navbar.discover_all', navbar.discover_all);
                          headerCategoryDropdownDiscoverClicked(countryMap[country], 'Floss');
                        }}
                        id="header-discover_all-link"
                      />
                    </div>
                  </a>
                </li>
                )}
                {(sonicBrushHeadMarkets.includes(country) || sonicGoBrushHeadMarkets.includes(country) || sonicGentleBrushHeadMarkets.includes(country)) && (
                <li>
                  <a
                    data-lokalise=""
                    data-key="navbar::menu_item_5"
                    onClick={() => { headerCategoryClicked(countryMap[country], 'Refills'); setActiveMenu(activeMenu === 4 ? 0 : 4); return false; }}
                    id="header-refills-link"
                    className={`${activeMenu === 4 ? 'active-cat' : ''} ${headerCategory === 'Refills' ? 'selected-cat' : ''}`}
                    role="presentation"
                  >
                    {checkRender(sonicGentleBrushHeadMarkets.includes(country)
                      , (checkRender(isShopifyEnabledMarket, (
                        <a
                          referrerPolicy="no-referrer"
                          rel="noreferrer"
                          id="header-zenyum-refills-category-link"
                          href={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${CATEGORY_URL}${END_POINTS.ZENYUM_REFILLS}`}
                          className="shopify-links"
                          target="_blank"
                        >
                          <span role="presentation">{navbar.menu_item_5}</span>
                        </a>)
                      , (
                        <Link href="/[lng]/zenyum-refills" as={`/${i18n.activeLocale}/zenyum-refills`} prefetch={false}>
                          <span>{navbar.menu_item_5}</span>
                        </Link>
                      )))
                      , (
                        <span>{navbar.menu_item_5}</span>
                      ))}
                    <span className="plus" />
                    <div className={`sub-nav ${activeMenu === 4 ? 'active-menu' : ''}`} style={{ display: 'none' }}>
                      <h4>{navbar.featured}</h4>
                      <ul>
                        {/* Desktop Link Starts here */}
                        {/* Desktop Link Ends here */}
                        {/* Common Link Starts here */}

                        {sonicBrushHeadMarkets.includes(country) && (
                          <li className="common-link">
                            <NavbarProduct
                              shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUMSONIC_BRUSH_HEAD_THREE_PACK}`}
                              isCommonLink={true}
                              dataKey="navbar::menu_refills_1"
                              onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Refills', 'Sonic Brushhead'); }}
                              id="header-menu_refills_1-link"
                              imgSrc="/img/updated/navbar/menu/refills_1.png"
                              content="menu_refills_1"
                            />
                          </li>
                        )}
                        {sonicGoBrushHeadMarkets.includes(country) && (
                        <li className="comomon-link">
                          <NavbarProduct
                            shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUMSONIC_GO_BRUSH_HEAD_THREE_PACK}`}
                            isCommonLink={true}
                            dataKey="navbar::menu_refills_2"
                            onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Refills', 'SonicGo Brushhead'); }}
                            id="header-menu_refills_2-link"
                            imgSrc="/img/updated/navbar/menu/refills_2.png"
                            content="menu_refills_2"
                          />
                        </li>
                        )}
                        {waterflosserTipsMarkets.includes(country) && (
                        <li className="common-link">
                          <NavbarProduct
                            shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${END_POINTS.ZENYUM_WATERFLOSSER_PRO_STANDARD_JET_TIPS_REFILL_PACK}`}
                            isCommonLink={true}
                            dataKey="navbar::menu_refills_3"
                            onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Refills', 'Waterflosser Tips'); }}
                            id="header-menu_refills_3-link"
                            imgSrc="/img/updated/navbar/menu/refills_3.png"
                            content="menu_refills_3"
                          />
                        </li>
                        )}
                        {sonicGentleBrushHeadMarkets.includes(country)
                        && (
                        <li className="comomon-link">
                          <NavbarProduct
                            shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PRODUCT_URL}${country !== 'hk' ? END_POINTS.ZENYUMSONIC_REFILL_GENTLE_CLEAN_BRUSH_HEAD_TWO_PACK : END_POINTS.ZENYUMSONIC_GENTLE_CLEAN_BRUSH_HEAD_FOUR_PACK}`}
                            isCommonLink={true}
                            dataKey="navbar::menu_refills_4"
                            onClickFunction={() => { headerCategoryDropdownClicked(countryMap[country], 'Refills', 'Sonic Gentle Brushhead'); }}
                            id="header-menu_refills_4-link"
                            imgSrc="/img/updated/navbar/menu/refills_4.png"
                            content="menu_refills_4"
                          />
                        </li>
                        )}
                        {/* Common Link Ends here */}
                        {/* Mobile Link starts here */}
                        {/* Mobile Link Ends here */}
                      </ul>
                      {sonicGentleBrushHeadMarkets.includes(country)
                        && (
                        <NavbarDiscover
                          shopifyHref={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${CATEGORY_URL}${END_POINTS.ZENYUM_REFILLS}`}
                          dataKey="navbar::discover_all"
                          onClickFunction={() => { buttonClick('navbar.discover_all', navbar.discover_all); headerCategoryDropdownDiscoverClicked(countryMap[country], 'Gentle Brush Head'); }}
                          id="header-zenyumGentleBrushHead-link"
                        />
                        )}
                    </div>
                  </a>
                </li>
                )}
                {!cartDisabledMarkets.includes(country) && (
                <li>
                  {isShopifyEnabledMarket ? (
                    <a
                      data-lokalise=""
                      data-key="navbar::menu_item_6"
                      referrerPolicy="no-referrer"
                      rel="noreferrer"
                      onClick={() => { shopAllClicked(countryMap[country], 'Header'); setActiveMenu(0); return false; }}
                      id="header-shopall-link"
                      href={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PAGES_URL}${END_POINTS.SHOPALL}`}
                      className={headerCategory === 'Shop all' ? 'selected-cat' : ''}
                      target="_blank"
                    >
                      <span>{navbar.menu_item_6}</span>
                    </a>
                  ) : (
                    <Link href="/[lng]/shopall" as={`/${i18n.activeLocale}/shopall`}>
                      <a
                        data-lokalise=""
                        data-key="navbar::menu_item_6"
                        role="presentation"
                        onClick={() => { shopAllClicked(countryMap[country], 'Header'); setActiveMenu(0); return false; }}
                        id="header-shopall-link"
                        className={headerCategory === 'Shop all' ? 'selected-cat' : ''}
                      >
                        <span>{navbar.menu_item_6}</span>
                      </a>
                    </Link>
                  )}
                </li>
                )}
              </>
              )}
            </ul>
            <div className="navbar-gap">
                &nbsp;
            </div>
            <ul className="about-discover main-nav">
              <li>
                <a
                  data-lokalise=""
                  data-key="navbar::about"
                  onClick={() => { setActiveMenu(activeMenu === 5 ? 0 : 5); return false; }}
                  id="header-about-link"
                  className={`${activeMenu === 5 ? 'active-cat' : ''} ${headerCategory === 'about' ? 'selected-cat' : ''}`}
                  href="#"
                >
                  <a
                    data-lokalise=""
                    data-key="navbar::about"
                    href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about`}
                  >
                    <span>{navbar.about}</span>
                  </a>
                  <span className="plus" />
                  <div className={`sub-nav ${activeMenu === 5 ? 'active-menu' : ''}`} style={{ display: 'none' }}>
                    <ul className="">
                      <li>
                        <a
                          data-lokalise=""
                          data-key="navbar::about_revamp_link_1"
                          href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-whatwedo`}
                        >
                          <p>{navbar.about_revamp_link_1}</p>
                        </a>
                      </li>
                      <li>
                        <a
                          data-lokalise=""
                          data-key="navbar::about_revamp_link_2"
                          href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-story`}
                        >
                          <p>{navbar.about_revamp_link_2}</p>
                        </a>
                      </li>
                      <li>
                        <a
                          data-lokalise=""
                          data-key="navbar::about_revamp_link_3"
                          href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-journey`}
                        >
                          <p>{navbar.about_revamp_link_3}</p>
                        </a>
                      </li>
                      <li>
                        <a
                          data-lokalise=""
                          data-key="navbar::about_revamp_link_4"
                          href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-partner`}
                        >
                          <p>{navbar.about_revamp_link_4}</p>
                        </a>
                      </li>
                      <li>
                        <a
                          data-lokalise=""
                          data-key="navbar::about_revamp_link_5"
                          href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-highlight`}
                        >
                          <p>{navbar.about_revamp_link_5}</p>
                        </a>
                      </li>
                      {country !== 'th' && (
                      <li>
                        <a
                          data-lokalise=""
                          data-key="navbar::about_revamp_link_6"
                          href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-community`}
                        >
                          <p>{navbar.about_revamp_link_6}</p>
                        </a>
                      </li>
                      )}
                      {!cartDisabledMarkets.includes(country) && (
                      <li>
                        <a
                          data-lokalise=""
                          data-key="navbar::about_revamp_link_7"
                          href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/about#about-join`}
                        >
                          <p>{navbar.about_revamp_link_7}</p>
                        </a>
                      </li>
                      )}
                    </ul>
                  </div>
                </a>
              </li>
              <li>
                <a
                  data-lokalise=""
                  data-key="navbar::discover"
                  onClick={() => { setActiveMenu(activeMenu === 6 ? 0 : 6); return false; }}
                  id="header-discover-link"
                  className={activeMenu === 6 ? 'active-cat' : ''}
                  href="#"
                >
                  <span>{navbar.discover}</span>
                  <span className="plus" />
                  <div className={`sub-nav ${activeMenu === 6 ? 'active-menu' : ''}`} style={{ display: 'none' }}>
                    <ul>
                      {!faqDisabledMarkets.includes(country) && (
                      <li>
                        <a
                          data-lokalise=""
                          data-key="navbar::faqs"
                          href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/faqs`}
                        >
                          <p>
                            {navbar.faqs}
                          </p>
                        </a>
                      </li>
                      )}
                      <li>
                        <a
                          data-lokalise=""
                          data-key="navbar::discover_link_1"
                          href={`${country !== 'hk' ? externalLinks[country].blog : externalLinks[country][lang].blog}${useUtmParam(query)}`}
                          id="navbar-blog"
                        >
                          <p>
                            {navbar.blog}
                          </p>
                        </a>
                      </li>
                      <li>
                        <a
                          data-lokalise=""
                          data-key="navbar::for_dentists"
                          href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/dentist`}
                          id="navbar-for_dentists"
                        >
                          <p>
                            {' '}
                            {navbar.for_dentists}
                          </p>
                        </a>
                      </li>
                      <li>
                        <a
                          data-lokalise=""
                          data-key="navbar::referral_rewards"
                          href={`${process.env.NEXT_PUBLIC_BASE_URL}${i18n.activeLocale}/referrals-mechanism`}
                          id="navbar-referral_rewards"
                        >
                          <p>
                            {navbar.referral_rewards}
                          </p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </a>
              </li>

            </ul>
          </div>
        </nav>
      </div>
      {page === 'Home' && (
        <HomepageSubbar />
      )}
      {page === 'shop_aligner' && (
        <AlignerSubbar />
      )}
      <ToastItem page={page} />

      <div className="orientation-msg">
        <div>
          <h2>{orientationMsg.title}</h2>
          <p>{orientationMsg.description}</p>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  page: PropTypes.string,
  segName: PropTypes.string,
  headerCategory: PropTypes.string
};

Header.defaultProps = {
  page: 'home',
  segName: '',
  headerCategory: ''
};
