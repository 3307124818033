import React from 'react';

import PropTypes from 'prop-types';

import useI18n from '../../hooks/use-i18n';

const NavbarDiscover = (props) => {
  const {
    dataKey,
    id,
    onClickFunction,
    shopifyHref
  } = props;

  const i18n = useI18n();
  const navbar = i18n.t('navbar');
  return (
    <div className="nav-box-bottom">
      <a
        data-lokalise=""
        data-key={dataKey}
        referrerPolicy="no-referrer"
        rel="noreferrer"
        href={shopifyHref}
        onClick={onClickFunction}
        id={id}
        target="_blank"
      >
        {navbar.discover_all}
      </a>

    </div>
  );
};
NavbarDiscover.propTypes = {
  dataKey: PropTypes.string,
  id: PropTypes.string,
  onClickFunction: PropTypes.func,
  shopifyHref: PropTypes.string
};
NavbarDiscover.defaultProps = {
  dataKey: '',
  id: '',
  onClickFunction: () => {},
  shopifyHref: ''
};

export default NavbarDiscover;
