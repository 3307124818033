import { useEffect, useRef } from 'react';

import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import ouibounce from 'ouibounce';
import PropTypes from 'prop-types';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { getConfig } from '../../apis/config';
import { fetchProducts, fetchProductsSG } from '../../apis/products';
import { pageViewEvent } from '../../helpers/analytics';
import useI18n from '../../hooks/use-i18n';
import { contentLanguageMap, countryMap, hrefLanguages } from '../../language.config';
import { config } from '../../recoil/atoms/config';
import { productLists } from '../../recoil/atoms/productLists';
import { products } from '../../recoil/atoms/products';
import { productsReviews } from '../../recoil/atoms/productsReviews';
import { productsLoad } from '../../recoil/selectors/notifications';
import { setCookie, setLocalStorageItem, getLocalStorageItem, clearLocalStorageItems } from '../../utils/storage';
import { getAllProducts, loadGumlet, fetchBannerConfig } from '../../utils/utils';
import Footer from '../revamp/footer';
import Header from '../revamp/header';

import '../../styles/layout.scss';
import '../../styles/globals.scss';

const JpHeader = dynamic(() => import('../jp-revamp/jp-header'), { ssr: false });
const JpFooter = dynamic(() => import('../jp-revamp/jp-footer'), { ssr: false });

export default function Layout(props) {
  const {
    children,
    title = 'Zenyum',
    insiderPage,
    page,
    type,
    keywords,
    description,
    noIndex,
    addToCartButton,
    subTotalValue,
    onPopupClose,
    showFooter,
    analyticData,
    showHeader,
    headerCategory,
    pageUrl,
    pageEnabledMarkets,
    inAppMessageEnabled,
    customEvent
  } = props;
  const [productsList, setProductsList] = useRecoilState(products);
  const setProductsListReviews = useSetRecoilState(productsReviews);
  const [useconfig, setConfig] = useRecoilState(config);
  const setProductsLoaded = useSetRecoilState(productsLoad);
  const setProductList = useSetRecoilState(productLists);

  const i18n = useI18n();
  const codes = i18n.activeLocale.split('-');
  const country = codes.length === 2 ? codes[0] : 'sg';
  const lang = codes[1] || 'en';
  const router = useRouter();
  const firstRender = useRef(true);
  const query = router && router.query;

  useEffect(() => {
    Object.keys(query).forEach((utmParam) => {
      if (utmParam.startsWith('utm_') || utmParam === 'gclid') {
        setCookie(utmParam, query[utmParam]);
      }
    });
    if (process.env.NEXT_PUBLIC_LOKALISE_CONFIG && query.editLokalize) {
      startLokalizeLive();
    }
  }, [query]);

  useEffect(() => {
    if (customEvent) {
      customEvent.forEach((segment) => {
        if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
          window.analytics.track(segment);
        }
      });
    }
  }, [customEvent]);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GTM_ID && window.insider_object) {
      window.insider_object.page = {
        type: insiderPage || page
      };
      // window.Insider?.eventManager?.dispatch('init-manager:re-initialize');
    }
  }, [page]);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_SEGMENT_ID && (page || analyticData) && page !== 'none') {
      window.analytics?.page(analyticData?.name || page, { ...analyticData?.properties, type: 'page' }, {
        context: {
          location: {
            country: countryMap[country]
          }
        }
      });
      window.analytics.ready(() => {
        window.appboy?.registerAppboyPushMessages();
        if (inAppMessageEnabled) { window.appboy?.display.automaticallyShowNewInAppMessages(); }
        ouibounce(false, {
          callback() { window.appboy?.logCustomEvent('exit intent'); }
        });
        // if (window.appboy.getCachedContentCards().getUnviewedCardCount() > 0) {
        //   window.appboy.display.showContentCards();
        // }
      });
    }
    const jsonLdScript = document.createElement('script');
    jsonLdScript.type = 'application/ld+json';
    jsonLdScript.innerHTML = JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      name: 'Zenyum',
      url: 'https://www.zenyum.com/'
    });
    document.head.appendChild(jsonLdScript);
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (Object.keys(productsList).length === 0) fetchProductsList();
    if (!useconfig.loaded) fetchConfig();
    if (productLists) {
      setTimeout(() => {
        pageViewEvent(page, router.asPath, type);
      }, 1000);
    }
    loadGumlet();
    if (process.env.NEXT_PUBLIC_GTM_ID) {
      return () => {
        window.dataLayer.push({ ecommerce: undefined });
      };
    }
  }, []);

  useEffect(() => {
    if (getLocalStorageItem('country') !== country) {
      clearLocalStorageItems();
      fetchProductsList();
      setLocalStorageItem('country', country);
      fetchConfig();
      setProductList({});
    } else {
      if (firstRender.current) {
        firstRender.current = false;
        return;
      }
      fetchConfig();
    }
  }, [country, i18n.activeLocale]);

  const fetchProductsList = async () => {
    const res = await fetchProducts();
    setProductsLoaded(true);
    const productsRes = res || {};
    if (res) {
      const productCatalogue = {};
      const productData = getAllProducts(productsRes);
      productData.forEach((item) => {
        if (!productCatalogue[item.category]) productCatalogue[item.category] = [];
        productCatalogue[item.category].push(item);
      });
      window.productData = productCatalogue;
      window.productCartData = productsRes;
    }
    if (country === 'my') {
      const resSg = await fetchProductsSG();
      const productsResSg = resSg || {};
      setProductsListReviews({ ...productsRes, ...productsResSg });
    } else {
      setProductsListReviews(productsRes);
    }
    setProductsList(productsRes);
  };

  const startLokalizeLive = () => {
    const locale = contentLanguageMap[i18n.activeLocale];
    window.LOKALISE_CONFIG = {
      projectId: process.env.NEXT_PUBLIC_LOKALISE_CONFIG,
      locale
      // onSave (updatedTranslations, skippedTranslations, dismissedTranslations, errorMessage) {
      //   console.log(updatedTranslations);
      //   if (skippedTranslations.length) alert('Save Error');
      //   else alert('Save Success');
      // }
    };
    (function () {
      const a = document.createElement('script'); a.type = 'text/javascript'; a.async = !0;
      a.src = ['https://app.lokalise.com/live-js/script.min.js?', (new Date()).getTime()].join('');
      document.body.appendChild(a);
    }());
  };

  const fetchConfig = async () => {
    const configRes = await getConfig(i18n.activeLocale);
    if (configRes) {
      const bannerData = fetchBannerConfig(configRes);
      setConfig({ ...bannerData });
    }
  };

  useEffect(() => {
    if (useconfig) { loadGumlet(); }
  }, [useconfig]);

  return (
    <div className="wrapper">
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content="Zenyum" />
        <meta name="zalo-platform-site-verification" content="PlEiCgJzAWiaq8udnVCzBqV6hJUhjtPjCZ4" />
        <meta httpEquiv="content-language" content={lang} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content="Zenyum" />
        {(process.env.NEXT_PUBLIC_BASE_URL.includes('stg')
        || process.env.NEXT_PUBLIC_BASE_URL.includes('dev') || noIndex) && (
          <meta name="robots" content="noindex" />
        )}
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_BASE_URL}img/FB_zenyum_02.jpg`}
        />
        <link rel="canonical" href={`https://www.zenyum.com/${i18n.activeLocale}${pageUrl ? `/${pageUrl}` : ''}`} />

        {((pageUrl || page === 'Home') && i18n.activeLocale) && Object.entries(hrefLanguages).map(([locale, value]) => {
          const currentCode = locale.split('-');
          const currentCountry = currentCode.length === 2 ? currentCode[0] : '';
          if (pageEnabledMarkets?.includes(currentCountry) || pageEnabledMarkets.length === 0) {
            return (
              <link
                rel="alternate"
                hrefLang={value}
                href={`https://www.zenyum.com/${locale}${pageUrl ? `/${pageUrl}` : ''}`}
                key={locale}
              />
            );
          }
          return false;
        })}

        <link rel="icon" href={`${process.env.NEXT_PUBLIC_BASE_URL}favicon_32x32.png`} />
        <link rel="icon" href="/favicon_32x32.png" type="image/png" sizes="32x32" />
        <link rel="icon" href="/favicon_192x192.png" type="image/png" sizes="192x192" />
        <link rel="apple-touch-icon" href="/favicon.png" />
        {lang !== 'vi' && lang !== 'th' && lang !== 'zh' && (
          <link rel="stylesheet" href="/fonts/fonts.css" />
        )}
        {lang === 'vi' && <link rel="stylesheet" href="/fonts/fonts-vi.css" />}
        {lang === 'th' && <link rel="stylesheet" href="/fonts/fonts-th.css" />}
        {lang === 'zh' && <link rel="stylesheet" href="/fonts/fonts-zh.css" />}
        {lang === 'ja' && <link rel="stylesheet" href="/fonts/fonts-jp.css" />}
        {lang === 'id' && <link rel="stylesheet" href="/fonts/fonts-id.css" />}
        <link rel="stylesheet" href="/vendor/bootstrap/css/bootstrap.min.css" async={true} />
        {process.env.NEXT_PUBLIC_INSIDER_PARTNER_NAME && (
          <script
            async={true}
            src={`//${process.env.NEXT_PUBLIC_INSIDER_PARTNER_NAME}.api.useinsider.com/ins.js?id=
          ${process.env.NEXT_PUBLIC_INSIDER_PARTNER_ID}`}
          />
        )}
        {/* <script src="https://www.googleoptimize.com/optimize.js?id=OPT-P45NSZP" /> */}
        <script
          src="https://widget.reviews.io/carousel-inline-iframeless/dist.js?_t=2021111603"
        />
        <link
          rel="stylesheet"
          href="https://assets.reviews.io/css/widgets/carousel-widget.css?_t=2021111603"
          defer={true}
        />
        <link
          rel="stylesheet"
          href="https://assets.reviews.io/iconfont/reviewsio-icons/style.css?_t=2021111603"
          defer={true}
        />
        {/* <script src="https://widget.reviews.io/polaris/build.js" />
        <script src="https://widget.reviews.io/rating-snippet/dist.js" />
        <link rel="stylesheet" href="https://widget.reviews.io/rating-snippet/dist.css" defer={true} /> */}
        {/* End (noscript) */}
      </Head>
      {/* Google Tag Manager (noscript) */}
      {process.env.NEXT_PUBLIC_GTM_ID && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
            height="0"
            width="0"
            title="gtag"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
      )}
      {/* End Google Tag Manager (noscript) */}
      {showHeader && (
        country !== 'jp' ? (
          <Header
            page={page}
            addToCartButton={addToCartButton}
            subTotalValue={subTotalValue}
            onPopupClose={onPopupClose}
            segName={analyticData?.name || page}
            headerCategory={headerCategory}
          />
        ) : (
          <JpHeader page={page} />
        )
      )}
      {children}
      <div className={showFooter ? 'd-block' : 'd-none d-md-block'}>
        {
          country !== 'jp' ? (
            <Footer page={analyticData?.name || page} />
          ) : (
            <JpFooter />
          )
        }
      </div>
    </div>

  );
}

Layout.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  page: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
  insiderPage: PropTypes.string,
  keywords: PropTypes.string,
  noIndex: PropTypes.bool,
  addToCartButton: PropTypes.shape(),
  subTotalValue: PropTypes.number,
  onPopupClose: PropTypes.func,
  showFooter: PropTypes.bool,
  analyticData: PropTypes.shape(),
  inAppMessageEnabled: PropTypes.bool,
  showHeader: PropTypes.bool,
  headerCategory: PropTypes.string,
  pageUrl: PropTypes.string,
  pageEnabledMarkets: PropTypes.array,
  customEvent: PropTypes.array
};

Layout.defaultProps = {
  children: undefined,
  title: 'Zenyum',
  page: 'none',
  type: 'general',
  insiderPage: '',
  description: 'Zenyum',
  keywords: 'Zenyum',
  noIndex: false,
  addToCartButton: {},
  analyticData: {},
  subTotalValue: 0,
  showFooter: true,
  inAppMessageEnabled: true,
  showHeader: true,
  onPopupClose: () => { },
  headerCategory: ' ',
  pageUrl: ' ',
  pageEnabledMarkets: [],
  customEvent: []
};
