export const productImpression = (currencyCode, impressions) => {
  if (process.env.NEXT_PUBLIC_GTM_ID) {
    window?.dataLayer?.push({
      ecommerce: {
        currencyCode,
        impressions
      }
    });
  }
};

export const viewItemList = (data, currencyCode) => {
  if (process.env.NEXT_PUBLIC_GTM_ID) {
    if (data) {
      window?.dataLayer?.push({
        event: 'view_item_list',
        ecommerce: {
          // item_list_id: listId,
          currency: currencyCode,
          items: data
        }
      });
    }
  }
};

export const pageViewEvent = (pageTitle, pagePath, type) => {
  if (process.env.NEXT_PUBLIC_GTM_ID) {
    window?.dataLayer?.push({
      event: type === 'ecom' ? 'ec_pageview' : 'pageview',
      pageTitle,
      pagePath
    });
  }
};

export const clinicFormSubmissionEvent = (formData) => {
  if (process.env.NEXT_PUBLIC_GTM_ID) {
    window?.dataLayer?.push({
      event: 'clinicFormSubmission',
      ...formData
    });
  }
};

export const productClick = (currencyCode, productObj) => {
  if (process.env.NEXT_PUBLIC_GTM_ID) {
    window?.dataLayer?.push({
      event: 'productClick',
      ecommerce: {
        currencyCode,
        click: {
          actionField: { list: 'Shop All' }, // Optional list property.
          products: [{
            name: productObj.name, // Name or ID is required.
            id: productObj.id,
            price: productObj.price,
            brand: 'Zenyum',
            category: productObj.catalogue.category,
            variant: productObj.color
          }]
        }
      }
    });
  }
};

export const checkoutEvent = (currencyCode, productsList, cartPriceDetails) => {
  if (process.env.NEXT_PUBLIC_GTM_ID && productsList && cartPriceDetails) {
    // const data = productsList.map((product) => {
    //   const productDataModified = {};
    //   const keysNotToModify = ['price', 'quantity', 'discount', 'coupon'];
    //   Object.keys(product).forEach((key) => {
    //     if (!keysNotToModify.includes(key)) productDataModified[`item_${key}`] = product[key];
    //     else productDataModified[key] = product[key];
    //   });
    //   return productDataModified;
    // });
    const couponData = cartPriceDetails.coupons.map((coupon) => coupon.couponCode).join('_');
    window?.dataLayer?.push({
      event: 'begin_checkout',
      ecommerce: {
        currency: currencyCode,
        value: cartPriceDetails.total,
        discount: cartPriceDetails.discount,
        coupon: couponData,
        // checkout: {
        //   products: productsList
        // }
        items: productsList
      }
    });
  }
};
