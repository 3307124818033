import { selector } from 'recoil';

import notificationsState from '../atoms/notifications';

export const cartPopup = selector({
  key: 'cartPopup',
  get: ({ get }) => {
    const notifications = get(notificationsState);
    return notifications.cartPopup;
  },
  set: ({ set, get }, newValue) => set(notificationsState, { ...get(notificationsState), cartPopup: newValue })
});

export const productsLoad = selector({
  key: 'productsLoad',
  get: ({ get }) => {
    const notifications = get(notificationsState);
    return notifications.productsLoad;
  },
  set: ({ set, get }, newValue) => {
    set(notificationsState, { ...get(notificationsState), productsLoad: newValue });
  }
});

export const toast = selector({
  key: 'toast',
  get: ({ get }) => {
    const notifications = get(notificationsState);
    return notifications.toast;
  },
  set: ({ set, get }, newValue) => set(notificationsState, { ...get(notificationsState), toast: newValue })
});
