/* eslint-disable react/no-danger */
import { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';

import { toast } from '../../recoil/selectors/notifications';

import '../../styles/alert.scss';

export default function ToastItem({ page }) {
  const [toastContent, setToastContent] = useRecoilState(toast);

  useEffect(() => {
    setTimeout(() => {
      setToastContent(false);
    }, 5000);
  }, [toastContent]);

  return (
    <>
      {toastContent && (
        <div
          className={`toast-wrapper ${page === 'cart' ? 'cart-page' : ''} ${page === 'shop_fresh' ? 'fresh-page' : ''}`}
        >
          <p dangerouslySetInnerHTML={{ __html: toastContent }} />
        </div>
      )}
    </>
  );
}

ToastItem.propTypes = {
  page: PropTypes.string
};

ToastItem.defaultProps = {
  page: 'home'
};
