/* eslint-disable max-len */
import { useState, useEffect, useRef } from 'react';

import { useRouter } from 'next/router';
import { Container } from 'reactstrap';

import { freeAssessmentClicked } from '../../helpers/segment';
import useI18n from '../../hooks/use-i18n';
import { countryMap } from '../../language.config';
import { obt3EnabledMarkets } from '../../links.config';
import { useUtmParam as useUtmData } from '../../utils/utils';

import '../../styles/subbar.scss';

export default function AlignerSubbar() {
  const i18n = useI18n();
  const codes = i18n.activeLocale.split('-');
  const country = codes.length === 2 ? codes[0] : 'sg';
  const lang = codes[1] || 'en';
  const router = useRouter();
  const query = router && router.query;
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, toggleIsMenuOpen] = useState(false);
  const [isSticky, toggleIsSticky] = useState(false);
  const [utmParams, setUtmParams] = useState('');
  const dropdown = useRef();
  const scrollEventListner = () => {
    toggleIsSticky(window.pageYOffset > 70);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollEventListner);
    document.addEventListener('mousedown', handleClick);
    return () => {
      window.removeEventListener('scroll', scrollEventListner);
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setUtmParams(useUtmData('Homepage'));
    }, 50);
  }, [query]);

  const handleClick = (e) => {
    if (dropdown.current && dropdown.current.contains(e.target)) {
      return;
    }
    toggleIsMenuOpen(false);
  };

  function valueChange() {
    setIsMobile(window.innerWidth < 672);
  }

  useEffect(() => {
    valueChange();
  }, []);

  const pageData = i18n.t('home_page_new');

  return (
    <>
      <section className={`product-secondary-navbar product-subbar-sm ${isMenuOpen ? 'active' : ''} ${isSticky ? 'sticky' : ''}`}>
        <Container>
          <div className="d-flex justify-content-end">
            <a
              className="btn web-btn web-btn--fill"
              onClick={() => { freeAssessmentClicked(countryMap[country], 'Home Sub-Header'); }}
              href={`${obt3EnabledMarkets.includes(country) ? process.env.NEXT_PUBLIC_OBT3_LINK
                : process.env.NEXT_PUBLIC_OBT_LINK}${country}-${lang}/intro/${utmParams}`}
            >
              {pageData.assessment_button}
            </a>
          </div>
        </Container>
      </section>
    </>
  );
}
